.searchPageContainer {
  width: 100%;
  display: block;
  float: left;
}

.searchSection {
  width: 100%;
  float: left;
  display: block;
  clear: both;
}

.searchPageContainer .topCenterMenu {
  margin-top: 80px;
  margin-bottom: 0px;
  text-align: center;
}

.searchPageContainer .entitiesContainer {
  width: 100%;
  padding: 0 45px;
  float: left;
  display: block;
  background: #fff;
}

.searchPageContainer .searchNoneResult {
  color: black;
  text-align: center;
}

.loadingOverlayContainer {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
}

.loadingOverlayContainer .loadingCenterFull {
  align-items: center;
  display: block;
  margin-top: 25%;
  margin-left: 49%;
}

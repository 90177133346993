.v {
  &-col {
    &--customise {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      flex-basis: 50%;
      width: 50%;
    }
  }
}

@media (min-width: 768px) {
  .v {
    &-col {
      &--customise {
        flex-basis: 33.33%;
        width: 33.33%;
      }
    }
  }
}
@media (min-width: 992px) {
  .v {
    &-col {
      &--customise {
        flex-basis: 25%;
        width: 25%;
      }
    }
  }
}

@media (min-width: 1440px) {
  .v {
    &-col {
      &--customise {
        flex-basis: 20%;
        width: 20% !important;
      }
    }
  }
}

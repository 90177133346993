.loadingOverlayContainer {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
}

.loadingOverlayContainer .loadingCenterFull {
  display: block;
  margin: auto auto;
}

.topSlider_home {
  width: 100%;
  background-color: #fff;
  float: left;
  display: block;
  overflow: hidden;
}

.imageSlider {
  width: 100%;
  filter: blur(30px);
  -webkit-filter: blur(30px);
}

.imageSliderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 470px;
}

.imageSliderContainer .imageSliderThumbShadow {
  width: 70%;
  -webkit-box-shadow: 0px 0px 42px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 42px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 42px -9px rgba(0, 0, 0, 0.75);
  height: 270px;
  border-radius: 4px;
  overflow: hidden;
}

.imageSliderContainer .imageSliderThumb {
  width: 100%;
  min-height: 270px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.imageSliderContainer .entityContent {
  width: 50%;
  display: block;
  float: left;
  min-height: 270px;
  margin-top: 100px;
}

.entityContent h2 {
  color: #ffffff;
  margin: 0;
  padding: 0;
  margin-left: 30%;
  text-shadow: 0px 0px 2px #000000;
  font-family: 'Roboto', sans-serif;
}

.entityContent h5 {
  color: #ffffff;
  width: 65%;
  margin-left: 30%;
  margin-top: 30px;
  line-height: 25px;
  text-shadow: 0px 0px 2px #000000;
  font-family: 'Roboto', sans-serif;
  min-height: 100px;
}

.entityContent .entityContentDetail {
  margin-left: 30%;
  display: block;
  float: left;
  margin-top: 5px;
}

.entityContentDetail .icon_view {
  color: #ffffff;
  text-shadow: 0px 0px 2px #000000;
}

.entityContentDetail .viewsText {
  color: #ffffff;
  margin-left: 10px;
  text-shadow: 0px 0px 2px #000000;
}

.entityContentDetail .viewsContainer {
  display: block;
  float: left;
}

.entityContentDetail .calendarContainer {
  display: block;
  float: left;
  margin-left: 30px;
}

.topSlider_home .playButton {
  width: auto;
  border-width: 0px;
  margin-top: 20px;
  height: 40px;
  background-color: #202124;
}

.topSlider_home .playButton:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.playButtonText {
  margin-left: 0px;
  color: #ffffff;
}

.icon_play {
  font-size: 14px;
  color: #ffffff;
}

.clearFix {
  clear: both;
}

.imageSliderContainer .entityImage {
  width: 50%;
  height: 270px;
  display: block;
  float: left;
  margin-top: 100px;
}

.topSlider_home .slick-dots {
  position: absolute;
  bottom: 0;
}

.topSlider_home .v-slide--prevButton {
  position: absolute;
  z-index: 99;
  cursor: pointer;
  left: 0;
  text-decoration: none;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 1s linear 0.33s,
    opacity 0.33s linear;
}

.topSlider_home .v-slide--nextButton {
  position: absolute;
  z-index: 99;
  cursor: pointer;
  right: 0;
  top: 0;
  text-decoration: none;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 1s linear 0.33s,
    opacity 0.33s linear;
}

.topSlider_home:hover .v-slide--prevButton {
  visibility: visible;
  opacity: 1;
}

.topSlider_home:hover .v-slide--nextButton {
  visibility: visible;
  opacity: 1;
}

.topSlider_home .v-slide--prevButton .prevButtonIcon {
  font-size: 1.25rem;
  position: absolute;
  top: 48%;
  left: 15px;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topSlider_home .v-slide--nextButton .nextButtonIcon {
  font-size: 1.25rem;
  font-weight: 400;
  position: absolute;
  top: 48%;
  right: 15px;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topSlider_home .v-slide--nextButton .nextButtonIcon:hover {
  background: #ffffff;
}

.topSlider_home .v-slide--prevButton .prevButtonIcon:hover {
  background: #ffffff;
}

.topSlider_home .v-slide--touchBanner i:before {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topSlider_home .slick-slider .slick-dots {
  width: auto;
  position: absolute;
  right: 5%;
  top: 90%;
}

.topSlider_home .slick-slider {
  position: relative;
}

.topSlider_home .slick-slider ul.slick-dots {
  list-style-type: none;
}

.topSlider_home .slick-slider ul.slick-dots li {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  margin-left: 12px;
}

.topSlider_home .slick-slider ul.slick-dots li button:before {
  width: 0;
  content: '';
}

.topSlider_home .slick-slider ul.slick-dots li.slick-active {
  height: 12px;
  width: 12px;
  position: relative;
  top: 0;
}

.topSlider_home .noSlider {
  width: 100%;
  height: 470px;
  float: left;
  background-color: #000000;
}

.sliderImage {
  display: flex;
  width: 60%;
  margin-left: 40%;
  max-height: 450px;
}

.sliderImage .gradient {
  position: absolute;
  z-index: 3;
  width: 40%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 212, 255, 0) 100%);
}

.movieContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 7rem 7rem;
}

.movieTitle {
  margin: 0 0 1.4rem;
  line-height: 1.1;
  font-size: 25px;
  color: #fff;
  letter-spacing: 0.4px;
  font-family: 'Roboto', sans-serif;
}

.movieTime {
  color: #999;
  margin-left: 20px;
}

.v-homePage ul.slick-dots li {
  background: #fff;
}
